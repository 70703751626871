<template>
  <router-view />
</template>

<style>
.game-btn {
  margin: 10px;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
  border: none;
  font-weight: bold;
  cursor: pointer;
  color: darkblue;
  transition-duration: 0.4s;
  border: solid 2px darkblue;
}
.game-btn:hover {
  background-color: darkblue;
  color: white;
}
body {
  margin: 0;
  color: darkblue;
}
</style>
