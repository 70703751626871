import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "team-building-dungeon-game",
    component: () => import("../components/KnightsGame/KnightsGameHome"),
  },
  {
    path: "/join/:id", // No leading slash for nested paths
    name: "join-game",
    component: () => import("../components/Shared/JoinGame"),
  },
  {
    path: "/game/:id", // No leading slash for nested paths
    name: "knights-game-ui",
    component: () => import("../components/KnightsGame/GameUI"),
  },
  // {
  //   path: "/game/test", // No leading slash for nested paths
  //   name: "knights-game-test",
  //   component: () => import("../components/Games/KnightsGame/KnightsGameTest"),
  // },
  // {
  //   path: "/generative-terrain-test", // No leading slash for nested paths
  //   name: "terrain-test",
  //   component: () => import("../components/Games/KnightsGame/OverworldGenTest"),
  // },
  // {
  //   path: "/wfc", // No leading slash for nested paths
  //   name: "fwc-test",
  //   component: () =>
  //     import("../components/Games/KnightsGame/WaveFunctionCollapseTest"),
  // },
  // {
  //   path: "/stamp", // No leading slash for nested paths
  //   name: "stamp-test",
  //   component: () => import("../components/Games/KnightsGame/StampTest"),
  // },
  // {
  //   path: "/simple", // No leading slash for nested paths
  //   name: "simple",
  //   component: () => import("../components/Games/KnightsGame/SimpleHillsTest"),
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
